<template>
  <div class="language">
    <div class="market_top">
      <div class="left">
        <div class="pic">
          <img src="../../assets/img/home_tatol_icon.png" alt />
        </div>
        <p class="title">BTCBAY</p>
      </div>
      <div @click="goback" class="system-closeb">
        <img src="../../assets/img/system-closeb.png" alt="" />
      </div>
    </div>
    <div class="language-item">
      <p
        @click="currencyCommand(item)"
        v-for="(item, index) in currencyList"
        :key="index"
        class="item"
        :class="currentCurrency == item ? 'select' : ''"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      // 'KRW', 'JPY'
      currencyList: ['USD', 'CNY'],
      currentCurrency: 'USD'
    };
  },
  created() {
    // 判断当前货币
    this.currentCurrency = localStorage.getItem('quoteCurrencyCode');
  },
  mounted() {},
  methods: {
    goback() {
      this.$router.push('/market');
    },
    currencyCommand(item) {
      this.currentCurrency = item;
      localStorage.setItem('quoteCurrencyCode', item);
      this.$router.push('/market');
    }
  }
};
</script>

<style lang="scss" scoped>
.language {
  .market_top {
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    padding: 0 28px 0 32px;
    // width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .pic {
        // padding-right: 20px;
        img {
          width: 56px;
          height: 56px;
          margin-right: 20px;
        }
        p {
          font-size: 38px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          line-height: 53px;
        }
      }
    }

    .system-closeb {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .language-item {
    .item {
      height: 89px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #999999;
      line-height: 89px;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      text-indent: 50px;
    }
    .select {
      color: #007aff;
    }
  }
}
</style>
